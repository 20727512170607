import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {
  return (
    <header>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img
              src="https://d21c62yfvjssc.cloudfront.net/assets/gruetzent-website/logo.png"
              alt="Gruetzmacher Enterprises Landscaping logo"
              width="273"
              height="100"
              className="d-inline-block align-text-top"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <a href="#services" className="nav-link fs-5 text-end ms-1 me-1">
                Services
              </a>
              <a href="#gallery" className="nav-link fs-5 text-end me-1">
                Gallery
              </a>
              <a href="#area" className="nav-link fs-5 text-end me-1">
                Service Area
              </a>
              <a href="#contact" className="nav-link fs-5 text-end me-1">
                Contact
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
