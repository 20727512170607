import React from 'react';

import Footer from './components/Footer';
import Home from './pages/Home';
import Header from './components/Header';

function App() {
  return (
    <main role="main">
      <Header />
      <Home />
      <Footer />
    </main>
  );
}

export default App;
