export const scrollToElement = (selector, offset = 0) => {
  const elementToScroll = document.querySelector(selector);

  if (!elementToScroll) {
    return;
  }

  window.scrollTo({
    top: elementToScroll.offsetTop - offset,
  });
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
