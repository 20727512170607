import React from 'react';

import { scrollToTop } from '../util';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 p-4">
            <div>© {currentYear} Gruetzmacher Enterprises LLC</div>
          </div>

          <div className="col-6 p-4 text-end">
            <button
              className="btn btn-link link-secondary"
              onClick={() => scrollToTop()}
            >
              Back to top
            </button>
          </div>
        </div>

        <div className="text-center mt-4 mb-4 text-muted">
          {`Website developed by `}
          <a
            href="https://www.etssoftworks.com"
            className="link-secondary"
            target="_blank"
            rel="noreferrer"
          >
            ETS Softworks
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
