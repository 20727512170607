import { useEffect } from 'react';

import { scrollToElement } from './util';

const useScrollToHash = (trigger = true, offset = 0) => {
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;

      if (hash) {
        scrollToElement(hash, offset);
      }
    };

    if (!trigger) {
      return;
    }

    scrollToHashElement();

    // Subscribe to event.
    window.addEventListener('hashchange', scrollToHashElement);

    // Unsubscribe to event.
    return window.removeEventListener('hashchange', scrollToHashElement);
  }, [offset, trigger]);
};

export default useScrollToHash;
