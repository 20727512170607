import React from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import siteData from '../siteData.json';

const GallerySection = () => {
  const { gallery } = siteData;

  return (
    <section id="gallery" className="section-gallery pt-4 pb-4 mb-4">
      <div className="container">
        <h2 className="text-center">Gallery</h2>

        <PhotoProvider
          loadingElement={<span className="fs-3 color-white">loading...</span>}
        >
          <div className="d-flex justify-content-center flex-wrap">
            {gallery?.images?.map((image, index) => (
              <PhotoView key={index} src={gallery.baseUrl + image.thumbnail}>
                {index < 11 ? (
                  <div
                    className="gallery-thumb"
                    style={{
                      backgroundImage: `url(${gallery.baseUrl + image.image})`,
                    }}
                  ></div>
                ) : index === 11 ? (
                  <div className="d-flex justify-content-center flex-wrap gallery-thumb image-placeholder">
                    <span className="align-self-center fs-3 color-white">
                      +{gallery.images.length - 11}
                    </span>
                  </div>
                ) : undefined}
              </PhotoView>
            ))}
          </div>
        </PhotoProvider>
      </div>
    </section>
  );
};

export default GallerySection;
