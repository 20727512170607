import React from 'react';
import {
  CheckCircleIcon,
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid';

import siteData from '../siteData.json';

import GallerySection from '../components/GallerySection';
import { scrollToElement } from '../util';

import useScrollToHash from '../useScrollToHash';

const Home = () => {
  const { services, serviceAreas, contact } = siteData;

  useScrollToHash();

  return (
    <>
      <section className="section-welcome">
        <div className="container h-100">
          <div className="row justify-content-end align-items-center h-100">
            <div className="col-xl-6 p-4 trans-bg">
              <h1>Gruetzmacher Enterprises Landscaping</h1>

              <p>
                A family owned business with more than 20 years of experience.
              </p>

              <button
                className="btn btn-primary btn-lg"
                onClick={() => scrollToElement('#contact')}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="section-services pt-4 pb-4">
        <div className="container">
          <h2 className="text-center">Landscaping Services</h2>

          <div className="mt-4">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
              {services
                ? services.map((service, index) => (
                    <div key={index} className="col">
                      <div className="card">
                        <div className="ms-3 mt-3 dot" />
                        <div className="card-body">
                          <div className="card-title h5">{service.name}</div>
                          <p className="card-text">{service.description}</p>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>

      <section className="section-banner">
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-xl-6 p-4 trans-bg">
              <h2>Reliable, Trusted, Professional</h2>

              <p>
                Quality workmanship with attention to detail. Fully Insured.
              </p>
            </div>
          </div>
        </div>
      </section>

      <GallerySection />

      <section id="area" className="section-service-area pt-4 pb-4">
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-md-6 mb-2">
              <h2 className="text-center">Service Area</h2>
            </div>

            <div className="col-md-6">
              <ul className="no-decoration">
                {serviceAreas
                  ? serviceAreas.map((serviceArea, index) => (
                      <li key={index} className="mt-4 mb-4">
                        <CheckCircleIcon className="heroicon" />
                        <span className="align-middle ms-2">{serviceArea}</span>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="pt-4 pb-4">
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-md-6 mb-2">
              <h2 className="text-center">Contact</h2>
            </div>

            <div className="col-md-6">
              <ul className="no-decoration">
                <li className="mt-4 mb-4">
                  <h4>Phone</h4>
                  <div className="text-nowrap">
                    <PhoneIcon className="heroicon" />
                    <a
                      href={`tel:${contact.phone}`}
                      className="align-middle ms-3 text-nowrap"
                    >
                      {contact.phone}
                    </a>
                  </div>
                </li>
                <li className="mt-4 mb-4">
                  <h4>Email</h4>
                  <div className="text-nowrap">
                    <EnvelopeIcon className="heroicon" />
                    <a
                      href={`mailto:${contact.email}`}
                      className="align-middle ms-3 "
                    >
                      {contact.email}
                    </a>
                  </div>
                </li>
                <li className="mt-4 mb-4">
                  <h4>Location</h4>
                  <div className="text-nowrap">
                    <MapPinIcon className="heroicon" />
                    <span className="align-middle ms-3">{contact.address}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
